.MuiTableRow-root .MuiTableCell-head {
    padding-left: 20px;
    padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-root {
    padding-left: 20px;
    padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-paddingNone {
    padding: 0px;
}

.MuiIconButton-root {
    cursor: pointer;
}

.asset_department {
    background-color: #fff;
    padding: 8px;
    height: auto;
}

.w-60 {
    width: 60%;
}

.margin-auto {
    margin: auto;
}

.none_wrap {
    white-space: nowrap !important;
}

.validator-form-scroll-dialog {
    overflow-y: auto !important;
    display: flex !important;
    flex-direction: column !important;
}

.logo-login {
    img {
        width: 100px;
        height: 100px;
    }
}

.form-login-container {
    height: calc(100% - 40px);
    width: 100%;
    max-width: 380px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    border: 0.5px solid black;
    padding: 50px 20px;
}

.login-botom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 12px;
}

.bg-transparent {
    background-color: transparent !important;
}

.icon-bar {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 0.7) !important;
    }
}

// .logo-menu{
//     max-width: 100px !important;
// }
// .logo-menu img{
//     width: 100% !important;
// }

.article-textField-button {
    position: relative;

    button {
        position: absolute;
        top: 14px;
        right: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 100%;
        max-height: 38px;
    }
}

body {
    .group_text-button {
        position: relative;

        button {
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 1000;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: 38px;
        }
    }

    .w-300 {
        width: 300px;
    }
}

ml-30 {
    margin-left: 30px i !important;
}

.color-red {
    color: red;
}

.color-green {
    color: green;
}

.loading-overlay.active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
    cursor: none;
    display: flex;
    align-items: center;
    justify-content: center
}

.icon {
    position: absolute;
    top: 0;
    cursor: pointer;
}

.search-icon {
    right: 24px;
}

.filter-icon {
    right: 0;
}

.cursor-move {
    cursor: move !important;
}

.text-red {
    color: red;
}

.max-h-400 {
    max-height: 400px !important;
}

.img-preview {
    width: 100%;
    height: 200px;
    object-fit: cover;
    max-width: 500px;
}

.btn-div {
    padding: 8px 15px;
    color: #fff;
    border-radius: 6px;
    background-color: var(--primary);
    cursor: pointer;
}

.flex-1 {
    flex: 1 !important;
}

.matrix-table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        border: 1px solid var(--primary);
        padding: 8px;
    }
}

.text-white {
    color: #fff !important;
}

.sign-in-container {
    width: 1200px !important;
    height: 600px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 6px -1px #fcfcfc;
}

.caption-text-sign-in {
    font-weight: 700;
    font-size: 18px;
    position: relative;
    bottom: 100px;
    text-align: center;
    color: #fff !important;
    -webkit-text-stroke: 0.2px black;
}

.image-reviewer {
    .review-image {
        width: 100px !important;
        height: 100px;
        cursor: pointer;
    }

    img .reviewer-image-dialog {
        width: 100% !important;
        height: auto;
    }
}